import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import { MetadataContent } from '@jutro/uiconfig';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from 'gw-digital-auth-react';
import { ApplicationHeader, SubApplicationHeader } from '@jutro/router';
import { PolicyService } from 'gw-capability-policy';
import { DropdownMenuLink } from '@jutro/components';
import metadata from './PageHeaderComponent.metadata.json5';
import pageHeaderStyles from './PageHeaderComponent.module.scss';
import messages from './PageHeaderComponent.messages';

function PageHeaderComponent(props) {
    const auth = useAuthentication();
    const { authHeader } = useAuthentication();
    const { routes } = props;
    const translator = useTranslator();
    const { env } = appConfig;
    const [isAdmin, updateIsAdmin] = useState();

    const getIsUserAdmin = useCallback(async () => {
        try {
            const result = await PolicyService.getIsUserAdmin(authHeader);
            updateIsAdmin(result);
        } catch {
            console.log('in a catch');
        }
    }, [authHeader]);

    const getAvatarLinks = useCallback(() => {
       // getIsUserAdmin();
        const links = [
            <DropdownMenuLink to="/userprofile">{translator(messages.account)}</DropdownMenuLink>,
            <DropdownMenuLink to="/faq">{translator(messages.faq)}</DropdownMenuLink>,
        ];
        if (!_.isUndefined(isAdmin) && isAdmin === true) {
            links.push([
                <DropdownMenuLink to="/userApprove">
                    {translator(messages.userapprove)}
                </DropdownMenuLink>
            ]);
        }
        if (!auth.useJutroAuthContext) {
            links.push([
                <DropdownMenuLink onClick={auth.logout}>
                    {translator(messages.logout)}
                </DropdownMenuLink>
            ]);
        }
        return links;
        // when using jutroAuthContext, jutro application header add the logout link by default
        // avoiding custom logout for gwcp and follow jutro
    }, [getIsUserAdmin, translator, isAdmin, auth.useJutroAuthContext, auth.logout]);

    const getLogoSrc = useCallback(() => {
        const url = _.get(env, 'DEPLOYMENT_URL');
        return `${url}/branding/logo-header.svg`;
    }, [env]);

    const overrides = {
        applicationheader: {
            avatarProps: {
                username: auth.user_name,
                title: auth.name,
                subtitle: auth.user_name
            },
            avatarChildren: getAvatarLinks(),
            logoSrc: getLogoSrc(),
        },
        applicationsubheader: {
            navigationRoutes: routes
        }
    };

    const resolvers = {
        resolveClassNameMap: pageHeaderStyles,
        resolveComponentMap: {
            applicationheader: ApplicationHeader,
            subapplicationheader: SubApplicationHeader
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            {...resolvers}
        />
    );
}

PageHeaderComponent.propTypes = {
    routes: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default PageHeaderComponent;
